// Import React and necessary hooks
import React, { useState, useEffect } from 'react';
import { GameCard } from './GameCard';
import { Game, Ranking } from './types'
import * as helpers from './helpers'

import './App.css';


function App() {
    const [gameData, setGameData] = useState<Game[]>([]);

    const fetchGameData = async () => {
        try {
            const response = await fetch('https://backend-jij275g6wq-uc.a.run.app/get_this_week');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setGameData(data);
            console.log(data)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const [rankingData, setRankingData] = useState<Ranking[]>([]);

    const fetchRankingData = async () => {
        try {
            const response = await fetch('https://backend-jij275g6wq-uc.a.run.app/get_rankings');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setRankingData(data);
            console.log(data)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchGameData();
        fetchRankingData();
    }, []);

    // Transform gameData array to group games by date
    const groupedGames = gameData.reduce((group, game) => {
        const date = helpers.formatDate(game.date);
        if (!group[date]) {
            group[date] = [];
        }
        group[date].push(game);
        return group;
    }, {} as Record<string, Game[]>);

    return (
        <div className="container">
            <header className="main-header">
                <nav className="navbar">
                    <span className="navbar-brand"><img src={require('./bookie.rip.png')} /></span>

                    <div className="navbar-nav">
                        <a href="#rankings" className="nav-item">Rankings</a>
                        <a href="#weekly-games" className="nav-item">This Week</a>
                        <a href="#about" className="nav-item">About</a>
                        <a href="#subscribe" className="nav-item">Subscribe</a>
                        <a className="header-icon nav-item fa fa-twitter" href="https://twitter.com/bookie_rip" target="_blank"></a>
                    </div>
                    
                </nav>
            </header>

            <section id="rankings">
                <div className="section-heading-container">
                    <h1 className="section-heading">
                        <i className="fa fa-star star-icon" aria-hidden="true"></i>
                        Power Rankings
                    </h1>
                </div>
                <table className="shared-table-style">
                    <thead>
                        <tr>
                            <th>Team</th>
                            <th>Relative Strength Index</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rankingData.map((ranking, index) => (
                            <tr key={index}>
                                <td>{ranking.Team}</td>
                                <td>
                                    <div className="progress-bar-container">
                                        <div className="progress-bar" style={{ width: `${ranking.RelativeStrengthIndex * 100}%` }}></div>
                                        <div className="progress-bar-text">
                                            {helpers.roundToTwo(ranking.RelativeStrengthIndex)}
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </section>

            <section id="weekly-games">
                <div className="section-heading-container">
                    <h1 className="section-heading">
                        <i className="fa fa-calendar calendar-icon" aria-hidden="true"></i>
                        Games This Week
                    </h1>
                </div>
                <div className="card-container">
                    {Object.entries(groupedGames).map(([date, games]) => (
                        <div key={date} className="date-group">
                            <h2 className="date-heading">{date}</h2>
                            <div className="cards-container">
                                {games.map((game, index) => (
                                    <GameCard key={index} game={game} />
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </section>
            {/* These sections are placeholders and will be implemented later */}

            <section id="about">
                <h1>About</h1>
                <div><strong>Where do the Bookmaker odds come from?</strong></div>
                <div>Bookmaker Odds are the average odds available from a variety of bookmakers including Bet365,
                    Interwetten, Pinnacle, and more.
                </div>
                <br></br>
                <div><strong>How are bookie.rip odds and predictions generated?</strong></div>
                <div>Odds and predictions are generated using the latest in machine learning technology including a
                    multi-headed neural network and an ensemble of other techniques.</div>
                <br></br>
                <div><strong>What is the Relative Strength Index?</strong></div>
                <div>The Relative Strength Index is a composite of the most important features of the odds
                    generation model. It also takes into account other factors such as form and strength of schedule</div>
            </section>

            <section id="subscribe">
                <h1>Subscribe</h1>
                <div>Coming soon! Check back for odds and predictions for Bundesliga, Scottish Premier League, Eredevise and more!</div>
                <div>For now, enjoy the free alpha!</div>
                <br></br>

            </section>

        </div>
    );
}

export default App;