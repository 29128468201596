import React from 'react';
import { Game } from './types'
import * as helpers from './helpers'

interface GameCardProps {
    game: Game;
}

interface GameCardProps {
    game: Game;
}

const getOddsComparison = (bookmakerOdds: number, yourOdds: number) => {
    const difference = yourOdds - bookmakerOdds;
    const colorClass = difference > 0 ? 'odds-better' : 'odds-worse';
    return <span className={colorClass}>{helpers.roundToTwo(yourOdds)}</span>;
};

export const GameCard: React.FC<GameCardProps> = ({ game }) => {
    return (
        <div className="card">
            {/* No header, as the team names will be in the body */}
            <div className="card-body">
                
            <div className="card-header">
                {game.homeTeam} vs. {game.awayTeam}
            </div>
                <div className="odds-group">
                    <div className="odds-entry">
                        <span>{game.homeTeam}:</span>
                        <span>{helpers.roundToTwo(game.avgH)}</span>
                        {getOddsComparison(game.avgH, game.odds_HomeWin)}
                    </div>
                    <div className="odds-entry">
                        <span>Draw:</span>
                        <span>{helpers.roundToTwo(game.avgD)}</span>
                        {getOddsComparison(game.avgD, game.odds_Draw)}
                    </div>
                    <div className="odds-entry">
                        <span>{game.awayTeam}:</span>
                        <span>{helpers.roundToTwo(game.avgA)}</span>
                        {getOddsComparison(game.avgA, game.odds_AwayWin)}
                    </div>
                </div>
                <div className="predictions">
                    <span>Expected {game.homeTeam} Goals: {helpers.roundToTwo(game.predicted_FTHG)}</span>
                    <span>Expected {game.awayTeam} Goals: {helpers.roundToTwo(game.predicted_FTAG)}</span>
                </div>
            </div>
        </div>
    );
}